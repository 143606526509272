body {
  margin: 0;
  height: 100vh;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  margin :0px;
}
and
.container {
  height: 100vh;
  border: 3px;
  box-sizing: border-box;
}


@font-face {
  font-family: 'Poppins-Medium ';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Medium'), url(src/assets/fonts/Poppins-Medium.ttf) format('woff');
}




@font-face {
  font-family: 'Roboto-Bold ';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Bold'), url(src/assets/fonts/Roboto-Bold.ttf) format('woff');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.select-placeholder-text {
  color: pink;
}



.filter {
  background: red;
}
